import {
  Button,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Link,
  Image,
  useDisclosure,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggle } from "../../reducers/sidebar";
import { AppContext } from "../../redux/appContext";
import { Link as ReachLink, useLocation, useNavigate } from "react-router-dom";
import { BiChevronDown, BiMenu } from "react-icons/bi";
import { bellIcon, subscriptinBadge, who } from "../../images";
import ls from "localstorage-slim";
import NotificationModal from "../../pages/dashboard/notification_modal";
import {
  httpGetWithTokenRest,
  httpPostWithoutToken,
} from "../../utils/http_utils";
import {
  dashboardRoute,
  plansRoute,
  profileRoute,
} from "../../utils/allRoutes";
import { setAuth, setTokens } from "../../utils/auth";

function Nav() {
  const toast = useToast();
  const { updateUser, setSub } = useContext(AppContext);
  const { activeUser, subscription } = useContext(AppContext);
  const pathname = useLocation().pathname;
  const user = activeUser;
  const families = ls.get("families", { decrypt: true });
  const active_family = ls.get("active_family", { decrypt: true });
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  const [isUnread, setIsUnread] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const isCoCaregiver = user.tag !== "cg";

  const {
    isOpen: isOpenInvite,
    onOpen: onOpenInvite,
    onClose: onCloseInvite,
  } = useDisclosure();

  const sidebarState = useSelector((state) => state.sidebar.value);
  const toggleSidebar = () => {
    var newState = "sidebar_active";
    if (sidebarState == "sidebar_active") {
      newState = "sidebar_inactive";
    }

    dispatch(toggle(newState));
  };
  useEffect(() => {
    if (dimensions.width < 768) {
      dispatch(toggle("sidebar_inactive"));
    } else {
      dispatch(toggle("sidebar_active"));
    }
  }, [dimensions]);

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    getConstants();
    window.addEventListener("resize", handleResize, false);
  }, []);
  useEffect(() => {
    if (window.innerWidth < 900) dispatch(toggle("sidebar_inactive"));
  }, [pathname]);

  const getConstants = async () => {
    var response = await httpGetWithTokenRest("notifications");
    if (response.error) {
    } else {
      var notis = response.data;
      var unread = notis.filter((n) => n.isRead === false);
      setIsUnread(unread.length > 0);
      setNotifications(response.data);
    }
  };
  const fetchUserPermission = async () => {
    const resp = await httpGetWithTokenRest("permissions");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      ls.set("sj_cocg_permission", resp.data.permissions, { encrypt: true });
    }
  };
  const loginToFamily = async (fam) => {
    setIsLoading(true);
    const resp = await httpPostWithoutToken("caregiver/families/auth", {
      ...fam,
      limitToken: true, // use token with expiration date
    });
    if (resp.error !== null && resp.error !== undefined) {
      setIsLoading(false);
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setIsLoading(false);
      ls.set("families", families, { encrypt: true });
      ls.set("active_family", fam, { encrypt: true });
      let u = resp.data.user;
      u.phone_number = u.phone_number
        ? u.phone_number.replace("undefined", "")
        : "";


        sessionStorage.setItem("sj_user", JSON.stringify(u));
        setTokens(resp.data.token, resp.data.refreshToken);
        setSub(resp.data.subscription);
  
        updateUser(u);
        await fetchUserPermission();
        setAuth(dispatch);
      navigate(dashboardRoute);
      window.location.href = "/dashboard"
        
    }
  };
  return (
    <Box
      display={"flex"}
      justifyContent="space-between"
      background="white"
      shadow={"md"}
      padding="10px 20px"
    >
      <Flex alignItems={"center"} gap="3">
        <Button display={{ base: "flex", md: "none" }} onClick={toggleSidebar}>
          <BiMenu size={"20"} />
        </Button>

        {families && families.length > 1 ? (
          <Menu>
            <MenuButton
              background="gray"
              border={"1px solid #EBECEF"}
              color="black"
              fontSize={"13px"}
              as={Button}
              rightIcon={<BiChevronDown />}
              borderRadius="60px"
              fontWeight={600}
            >
              {user?.family_name}
            </MenuButton>
            <MenuList border={0} shadow={"sm"}>
              {families.map((item, i) => (
                <MenuItem
                  onClick={() => {
                    if (item.family_id !== user.family_id) {
                      loginToFamily(item);
                    }
                  }}
                  key={i}
                >
                  {item.family_name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : (
          <Button
            background="gray"
            border={"1px solid #EBECEF"}
            color="black"
            fontSize={"13px"}
            as={Button}
            borderRadius="60px"
            fontWeight={600}
          >
            {user?.family_name}
          </Button>
        )}
        {isLoading && <Button width={"40px"} isLoading={isLoading}></Button>}
      </Flex>
      <NotificationModal
        coGiverConstants={notifications}
        onClose={onCloseInvite}
        isOpen={isOpenInvite}
        onFinished={getConstants}
      />

      <Flex alignItems={"center"} gap="3">
        <Link
          position="relative"
          as={ReachLink}
          to="#"
          onClick={onOpenInvite}
          borderRadius={"100%"}
          border="1px solid #EBECEF"
          padding="8px"
        >
          <Image boxSize={"22px"} objectFit="contain" src={bellIcon}></Image>
          {isUnread ? (
            <Text
              color="red"
              as="span"
              position="absolute"
              top="2px"
              right="3px"
            >
              {" "}
              &#9679;{" "}
            </Text>
          ) : (
            ""
          )}
        </Link>

        <Link
          display={{ base: "none", md: "block" }}
          style={{ textDecoration: "none" }}
          as={ReachLink}
          to={isCoCaregiver ? "" : plansRoute}
        >
          <Flex
            alignItems={"center"}
            borderRadius={"60px"}
            shadow={"sm"}
            bg="#EBECEF"
            gap="2"
            p="2"
          >
            <Image src={subscriptinBadge} boxSize={"22px"} />
            <Text fontWeight={"600"} fontSize={"12"} color={"#17181C"}>
              {subscription.plan_name} Plan
            </Text>
            {
              !isCoCaregiver
              &&
            
            <>
            {subscription.plan_name.toLowerCase() != "premium" &&
            <Box
              style={{ textDecoration: "none" }}
              fontWeight={"600"}
              fontSize={"12"}
              bg={"primary"}
              borderRadius={"25px"}
              color="white"
              p="2"
            >
              Upgrade
              </Box>
            }
            </>
            }

          </Flex>
        </Link>

        <Button
          border={"1px solid #EBECEF"}
          background="gray"
          color="black"
          fontSize={"13px"}
          as={Button}
          borderRadius="60px"
          fontWeight={600}
          onClick={() => navigate(profileRoute)}
        >
          <Flex alignItems={"center"} gap="2">
            <Image
              src={user?.avatar}
              boxSize="28px"
              objectFit={"cover"}
              borderRadius="100%"
            ></Image>
            <Box display={{ base: "none", md: "block" }}>
              {user?.first_name} {user?.last_name}
            </Box>
          </Flex>
        </Button>
      </Flex>
    </Box>
  );
}

export default Nav;
