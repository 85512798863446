import { ChevronLeftIcon, Icon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
  FormLabel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { deleteIconRed, healthcare, homeIcon1, homeIcon2 } from "../../images";
import { capitalizeFirstWord, checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { PlusIcon } from "@heroicons/react/24/solid";

export default function CaregiverListModal({
  isOpen,
  onClose,
  title = null,
  constants,
  onFinished,
  source = "main",
}) {
  const [list, setList] = useState([]);
  const [admin, setAdmin] = useState(null);
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const isCoCaregiver = user.tag !== "cg";

  const finishSelection = (item = null, y = "") => {
    onFinished(item, y);
    onClose();
  };
  useEffect(() => {
    setList(constants.filter((c) => c.cm_tag !== "admin"));
    setAdmin(constants.filter((c) => c.cm_tag == "admin"));
  }, [constants]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (source !== "main") {
          onFinished("close", "");
        } else {
          onClose();
        }
      }}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalBody marginBottom={"0"}>
          <Box display={"flex"} gap={3} alignItems={"center"} py={4}>
            <Box
              cursor={"pointer"}
              onClick={() => {
                finishSelection("close");
              }}
            >
              <ChevronLeftIcon fontSize={"24px"} />
            </Box>
            <Heading flex={1} fontSize={"16px"}>
              {capitalizeFirstWord(title)}'s Members
            </Heading>
            {isCoCaregiver || window.location.href.includes("type=careteam") ? (
              <></>
            ) : (
              <Box
                onClick={() => {
                  finishSelection("add");
                }}
                fontWeight={"bold"}
                display={"flex"}
                alignItems={"center"}
                cursor={"pointer"}
              >
                <Icon color={"#FF6E57"} as={PlusIcon} fontSize={"20px"} />
                <Text fontSize={"16"} color={"#FF6E57"}>
                  Add New
                </Text>
              </Box>
            )}
          </Box>
          {admin?.map((item, i) => (
            <Box
              bg={"#FAF8FF"}
              my={2}
              cursor={"pointer"}
              key={item.id}
              py="2"
              px="1"
              borderRadius={8}
            >
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <FormLabel
                  gap={"3"}
                  display="flex"
                  alignItems={"center"}
                  htmlFor={`${item.user_id}`}
                >
                  {item.avatar ? (
                    <Image
                      boxSize={"45px"}
                      borderRadius={"100%"}
                      src={item.avatar}
                    />
                  ) : (
                    <Box
                      position={"relative"}
                      fontWeight="600"
                      color="white"
                      boxSize={"45px"}
                      borderRadius={"100%"}
                      display="flex"
                      background={"secondary"}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text fontSize={"14px"}>
                        {item.first_name.substring(0, 1)}
                        {item.last_name.substring(0, 1)}
                      </Text>
                    </Box>
                  )}

                  <Box>
                    <Text fontWeight={"bold"}>
                      {item.first_name} {item.last_name}
                    </Text>

                    <>
                      <Box
                        w={"fit-content"}
                        p={2}
                        borderRadius={12}
                        bg={"#FBEAEA"}
                        fontSize={10}
                        color={"#FF6E57"}
                      >
                        {capitalizeFirstWord(item.cm_tag)}
                      </Box>
                    </>
                  </Box>
                </FormLabel>
                {item.cm_tag != "admin" && (
                  <Image
                    onClick={() => {
                      finishSelection("delete", item.user_id);
                    }}
                    boxSize={"18px"}
                    src={deleteIconRed}
                  />
                )}
              </Flex>
              <Divider mt={"1"} color={"#EBECEF"} />
            </Box>
          ))}
          {list?.map((item, i) => (
            <Box
              bg={"#FAF8FF"}
              my={2}
              cursor={"pointer"}
              key={item.id}
              py="2"
              px="1"
              borderRadius={8}
            >
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <FormLabel
                  gap={"3"}
                  display="flex"
                  alignItems={"center"}
                  htmlFor={`${item.user_id}`}
                >
                  {
                    item.avatar 
                    ?
                    <Image
                    boxSize={"45px"}
                    borderRadius={"100%"}
                    src={item.avatar}
                    />
                    :
                    <Box
                    position={"relative"}
                    fontWeight="600"
                    color="white"
                    boxSize={"45px"}
                    borderRadius={"100%"}
                    display="flex"
                    background={"secondary"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize={"14px"}>
                      {item.first_name.substring(0, 1)}
                      {item.last_name.substring(0, 1)}
                    </Text>
                  </Box>
                  }
                  
                  <Box>
                    <Text fontWeight={"bold"}>
                      {item.first_name} {item.last_name}
                    </Text>

                    <>
                      <Box
                        w={"fit-content"}
                        p={2}
                        borderRadius={12}
                        bg={"#FBEAEA"}
                        fontSize={10}
                        color={"#FF6E57"}
                      >
                        {capitalizeFirstWord(item.cm_tag)}
                      </Box>
                    </>
                  </Box>
                </FormLabel>
                {isCoCaregiver || window.location.href.includes("type=careteam") ? (
                  <></>
                ) : (
                  <>
                    {item.cm_tag != "admin" && (
                      <Image
                        onClick={() => {
                          finishSelection("delete", item.user_id);
                        }}
                        boxSize={"24px"}
                        src={deleteIconRed}
                      />
                    )}
                  </>
                )}
              </Flex>
              <Divider mt={"1"} color={"#EBECEF"} />
            </Box>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
