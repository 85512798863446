import { ChevronLeftIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  useCheckboxGroup,
  useToast,
  Icon,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import {
  circledoty,
  healthcare,
  homeIcon1,
  homeIcon2,
  notask,
  notificationTaskIcony,
} from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { useFormik } from "formik";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { COUNTRIES } from "../../utils/countries";
import PhoneNumberInput2 from "../../components/phoneNumberInput2";
import { httpPatchWithTokenRest, httpPostWithToken } from "../../utils/http_utils";
import moment from "moment";
import AppLink from "../../components/link";

export default function NotificationModal({
  isOpen,
  coGiverConstants,
  onClose,
  onFinished,
}) {
  const finishSelection = () => {
    setShowNotification(false)
    onClose();
  };
  const [showNotification, setShowNotification] = useState(false)
  const [notify, setNotify] = useState(null)
  const [constants, setConstants] = useState(coGiverConstants);
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));
  const toast = useToast();
  const validateEmail = (e) => {
    return String(e)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  useEffect(() => {
    setConstants(coGiverConstants);
  }, [coGiverConstants]);

  const readNotification = async (item) => {
    setNotify(item)
    setShowNotification(true)
    await httpPatchWithTokenRest(`notifications/${item.notificationId}`)
    onFinished()
  }
  // console.log(coGiverConstants)
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} p="3">
        {/* <ModalHeader>Invite Co-caregiver</ModalHeader>
            <ModalCloseButton> */}
        {/* <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton> */}
        <ModalBody marginBottom={"0"}>

          <Box>
            <Flex alignItems={"center"} justifyContent="space-between">
              <Flex
              alignItems={"center"}
              gap="3"
              >

              {
                showNotification ?
                <AppLink
                hasLeftIcon={true}
                leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
                shadow="none"
                padding="0"
                text=""
                background="transparent"
                color="black"
                width="fit-content"
                textDecoration="none"
                href="#?"
                onclick={()=>setShowNotification(false)}
              />
              : ""
              }
              <Heading fontSize={"20px"}>{showNotification ? "Notification" : "Notifications"}</Heading>
              </Flex>

              <Button onClick={finishSelection}>close</Button>
            </Flex>
            <Box mt={"3"} />
            {
              showNotification ? 
              <Flex py="3" gap="3" >
                <Image src={notificationTaskIcony} boxSize="44px" />
                <Box>
                  <Text fontSize={"14px"} color="black">
                    {notify?.message}
                  </Text>
                  <Flex
                    fontSize="12px"
                    color="#747A8B"
                    alignItems={"center"}
                    gap="2"
                  >
                    <Text>{moment(notify?.createdAt).format("ddd, MMM, Do")}</Text>
                    <Image src={circledoty} />
                    <Text>{moment(notify?.createdAt).format("h:mm:ss a")}</Text>
                  </Flex>
                </Box>
              </Flex>
              :
              <>
            {constants.map((item, i) => (
              <Flex onClick={()=>readNotification(item)} cursor={"pointer"} bg={item.isRead ? "white" : "gray"} borderRadius={'5px'} py="3" my="2" gap="3" key={i}>
                <Image src={notificationTaskIcony} boxSize="44px" />
                <Box>
                  <Text fontSize={"14px"} color="black">
                    {item.message}
                  </Text>
                  <Flex
                    fontSize="12px"
                    color="#747A8B"
                    alignItems={"center"}
                    gap="2"
                  >
                    <Text>{moment(item.createdAt).format("ddd, MMM, Do")}</Text>
                    <Image src={circledoty} />
                    <Text>{moment(item.createdAt).format("h:mm:ss a")}</Text>
                  </Flex>
                </Box>
              </Flex>
            ))}
            {constants.length === 0 ? (
              <>
                <Flex
                  flexDir={"column"}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Image src={notask} boxSize={"105px"}></Image>
                  <Box h="10px" />
                  <Heading as="h6" fontSize={"16px"}>
                    No Notifications
                  </Heading>
                  <Box h="10px" />

                  <Box textAlign={"center"} maxW="480px">
                    <Text color="paragraph">
                      No notifications yet. Notifications appears here when you
                      have any.
                    </Text>
                    <Box h="10px" />
                  </Box>
                </Flex>
              </>
            ) : (
              ""
            )}
            </>
         }
         </Box>


        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
