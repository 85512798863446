import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Spacer,
  Spinner,
  Stack,
  Tag,
  Text,
  Textarea,
  Tooltip,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { FaEdit, FaEllipsisV, FaFile, FaPlus, FaPrint } from "react-icons/fa";
import {
  carePlanImg,
  deleteIcon,
  powerAttImg,
  powerOfAttorney,
} from "../../images";
import {
  httpDeleteWithToken,
  httpGetWithTokenRest,
  httpPatchWithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import { useForm } from "react-hook-form";
import { COUNTRIES } from "../../utils/countries";
import {
  BsChevronLeft,
  BsDownload,
  BsFillTrash3Fill,
  BsUpload,
} from "react-icons/bs";
import ls from "localstorage-slim"
import { InfoOutlineIcon } from "@chakra-ui/icons";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { BiUserCircle } from "react-icons/bi";
import CustomModal from "../../components/customModal";
import download from "downloadjs";
import { PDFDocument } from "pdf-lib";
import { AppContext } from "../../redux/appContext";
import { useNavigate } from "react-router-dom";

export default function PowerOfAttorney() {
  const [powers, setPowers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [addPower, setAddPower] = React.useState(false);
  const [selectedPOA, setSelectedPOA] = React.useState(null);
  const { subscription } = useContext(AppContext);
  const navigate = useNavigate();
  const toast = useToast();
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));
  const [countryCodeAgent1, setCountryCodeAgent1] = React.useState({
    home_phone: "",
    work_phone: "",
    cellular_phone: "",
  });

  const [countryCodeAgent2, setCountryCodeAgent2] = React.useState({
    home_phone: "",
    work_phone: "",
    cellular_phone: "",
  });

  const [countryCodeAgent3, setCountryCodeAgent3] = React.useState({
    home_phone: "",
    work_phone: "",
    cellular_phone: "",
  });

  const [homePhoneAgent1, setHomePhoneAgent1] = React.useState({
    home_phone: "",
    work_phone: "",
    cellular_phone: "",
  });
  const [homePhoneAgent2, setHomePhoneAgent2] = React.useState({
    home_phone: "",
    work_phone: "",
    cellular_phone: "",
  });
  const [homePhoneAgent3, setHomePhoneAgent3] = React.useState({
    home_phone: "",
    work_phone: "",
    cellular_phone: "",
  });

  const [valuePhone, setValuePhone] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenUpload,
    onOpen: onOpenUpload,
    onClose: onCloseUpload,
  } = useDisclosure();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const [signatureImg, setSignatureImg] = React.useState(null);
  const [accessedPlanImg, setAccessedPlanImg] = React.useState(null);
  const [isDeleting, setIsDeleting] = React.useState(null);
  const signatureRef = React.useRef();
  const accessedPlanRef = React.useRef();
  const userPermission = ls.get("sj_cocg_permission", { decrypt: true });

  useEffect(() => {
    // if(subscription.power_of_attorney == "0") {
    //   return navigate("/access-denied")
    // }
  }, []);

  const chooseFile = () => {
    signatureRef.current.click();
  };

  const chooseFilePlan = () => {
    accessedPlanRef.current.click();
  };

  const imageHandler = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setSignatureImg(event.target.files[0]);
    }
  };

  const imageHandlerAP = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setAccessedPlanImg(event.target.files[0]);
    }
  };

  const fetchAllPOA = async () => {
    const resp = await httpGetWithTokenRest("caregivers/power_of_attorney");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setPowers(resp.data);
      setLoading(false);
    }
  };

  const handleDownloadPOA = () => {
    const lastCare = powers.slice(-1);
    console.log(lastCare[0]);
    editPdf(lastCare[0]);
    setAddPower(false);
    onClose();
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(values) {
    const data = {
      full_name: values.full_name,
      health_care_agents: {
        health_care_agent_one: {
          ...(values.agent1_full_name.length > 0 && {
            full_name: values.agent1_full_name,
          }),
          ...(values.agent1_house_address.length > 0 && {
            house_address: values.agent1_house_address,
          }),
          ...(homePhoneAgent1?.home_phone?.length > 0 && {
            home_tel: homePhoneAgent1.home_phone,
          }),
          ...(homePhoneAgent1?.work_phone?.length > 0 && {
            work_tel: homePhoneAgent1.work_phone,
          }),
          ...(homePhoneAgent1?.cellular_phone?.length > 0 && {
            cellular_tel: homePhoneAgent1.cellular_phone,
          }),
        },
        health_care_agent_two: {
          ...(values.agent2_full_name.length > 0 && {
            full_name: values.agent2_full_name,
          }),
          ...(values.agent2_house_address.length > 0 && {
            house_address: values.agent2_house_address,
          }),
          ...(homePhoneAgent2?.home_phone?.length > 0 && {
            home_tel: homePhoneAgent2.home_phone,
          }),
          ...(homePhoneAgent2?.work_phone?.length > 0 && {
            work_tel: homePhoneAgent2.work_phone,
          }),
          ...(homePhoneAgent2?.cellular_phone?.length > 0 && {
            cellular_tel: homePhoneAgent2.cellular_phone,
          }),
        },
        health_care_agent_three: {
          ...(values.agent3_full_name.length > 0 && {
            full_name: values.agent3_full_name,
          }),
          ...(values.agent3_house_address.length > 0 && {
            house_address: values.agent3_house_address,
          }),
          ...(homePhoneAgent3?.home_phone?.length > 0 && {
            home_tel: homePhoneAgent3.home_phone,
          }),
          ...(homePhoneAgent3?.work_phone?.length > 0 && {
            work_tel: homePhoneAgent3.work_phone,
          }),
          ...(homePhoneAgent3?.cellular_phone?.length > 0 && {
            cellular_tel: homePhoneAgent3.cellular_phone,
          }),
        },
      },
      physicians: [values.physician1, values.physician2],
      label: {
        date: values.label_date,
        full_name: values.label_full_name,
      },
    };
    if (subscription.power_of_attorney != "unlimited") {
      const sAttorney = parseInt(subscription.power_of_attorney);
      if (powers.length >= sAttorney) {
        return toast({
          title: "Access Denied!",
          description: `Please upgrade your account to create more Power of Attorney`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
    let resp;
    if (selectedPOA == null) {
      resp = await httpPostWithToken(
        `caregivers/power_of_attorney?status=draft`,
        data
      );
    } else {
      resp = await httpPutWithTokenRest(
        `caregivers/power_of_attorney/${selectedPOA?.id}?status=draft`,
        data
      );
    }

    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      fetchAllPOA();
      onOpen();
      reset();
    }
  }

  const handleDeletePower = async (powerId) => {
    setIsDeleting(true);
    const resp = await httpDeleteWithToken(
      `caregivers/power_of_attorney/${powerId}`
    );
    if (resp.error !== null && resp.error !== undefined) {
      setIsDeleting(false);
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setIsDeleting(false);
      fetchAllPOA();
      return toast({
        title: "Success.",
        description: `Power of attorney deleted successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditPower = async (values) => {
    setSelectedPOA(values);
    setHomePhoneAgent1({
      home_phone: values.health_care_agents?.health_care_agent_one?.home_tel,
      work_phone: values.health_care_agents?.health_care_agent_one?.work_tel,
      cellular_phone:
        values.health_care_agents?.health_care_agent_one?.cellular_tel,
    });
    setHomePhoneAgent2({
      home_phone: values.health_care_agents?.health_care_agent_two?.home_tel,
      work_phone: values.health_care_agents?.health_care_agent_two?.work_tel,
      cellular_phone:
        values.health_care_agents?.health_care_agent_two?.cellular_tel,
    });
    setHomePhoneAgent3({
      home_phone: values.health_care_agents?.health_care_agent_three?.home_tel,
      work_phone: values.health_care_agents?.health_care_agent_three?.work_tel,
      cellular_phone:
        values.health_care_agents?.health_care_agent_three?.cellular_tel,
    });

    reset({
      full_name: values.full_name,
      agent1_full_name:
        values.health_care_agents?.health_care_agent_one?.full_name,
      agent1_house_address:
        values.health_care_agents?.health_care_agent_one?.house_address,

      agent2_full_name:
        values.health_care_agents?.health_care_agent_two?.full_name,
      agent2_house_address:
        values.health_care_agents?.health_care_agent_two?.house_address,

      agent3_full_name:
        values.health_care_agents?.health_care_agent_three?.full_name,
      agent3_house_address:
        values.health_care_agents?.health_care_agent_three?.house_address,

      label_date: values.label?.date,
      label_full_name: values.label?.full_name,
      physician1: values.physicians[0],
      physician2: values.physicians[1],
    });
    setAddPower(true);
  };

  const {
    handleSubmit: handleSubmitUpload,
    register: registerUpload,
    reset: resetUpload,
    formState: { errors: uploadErrors, isSubmitting: isSubmittingUpload },
  } = useForm();

  async function onSubmitUpload(values) {
    const formData = new FormData();
    formData.append("file", accessedPlanImg);
    const resp = await httpPatchWithTokenRest(
      `caregivers/power_of_attorney/${selectedPOA?.id}`,
      formData
    );

    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      onCloseUpload();
      return toast({
        title: "Success.",
        description: `Document uploaded successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // onOpenConfirmation();
    }
  }

  const editPdf = async (e) => {
    console.log(e);
    const formUrl = window.location.href.includes("localhost")
      ? powerOfAttorney
      : "https://api.sojorne.com/api/v1/pdf/poa.pdf";
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();
    const fields = form.getFields();

    console.log(fields);
    const agent1_nameField = form.getTextField("agent1_name");
    const agent1_addressField = form.getTextField("agent1_address");
    const agent1_home_phoneField = form.getTextField("agent1_home_phone");
    const agent1_work_phoneField = form.getTextField("agent1_work_phone");
    const agent1_cellular_phoneField = form.getTextField(
      "agent1_cellular_phone"
    );

    const agent2_nameField = form.getTextField("agent2_name");
    const agent2_addressField = form.getTextField("agent2_address");
    const agent2_home_phoneField = form.getTextField("agent2_home_phone");
    const agent2_work_phoneField = form.getTextField("agent2_work_phone");
    const agent2_cellular_phoneField = form.getTextField(
      "agent2_cellular_phone"
    );

    const agent3_nameField = form.getTextField("agent3_name");
    const agent3_addressField = form.getTextField("agent3_address");
    const agent3_home_phoneField = form.getTextField("agent3_home_phone");
    const agent3_work_phoneField = form.getTextField("agent3_work_phone");
    const agent3_cellular_phoneField = form.getTextField(
      "agent3_cellular_phone"
    );

    const physician1Field = form.getTextField("physician1");
    const physician2Field = form.getTextField("physician2");

    //setting the fields
    agent1_nameField.setText(
      e?.health_care_agents?.health_care_agent_one?.full_name
    );
    agent1_addressField.setText(
      e?.health_care_agents?.health_care_agent_one?.house_address
    );
    agent1_home_phoneField.setText(
      e?.health_care_agents?.health_care_agent_one?.home_tel
    );
    agent1_work_phoneField.setText(
      e?.health_care_agents?.health_care_agent_one?.work_tel
    );
    agent1_cellular_phoneField.setText(
      e?.health_care_agents?.health_care_agent_one?.cellular_tel
    );

    agent2_nameField.setText(
      e?.health_care_agents?.health_care_agent_two?.full_name
    );
    agent2_addressField.setText(
      e?.health_care_agents?.health_care_agent_two?.house_address
    );
    agent2_home_phoneField.setText(
      e?.health_care_agents?.health_care_agent_two?.home_tel
    );
    agent2_work_phoneField.setText(
      e?.health_care_agents?.health_care_agent_two?.work_tel
    );
    agent2_cellular_phoneField.setText(
      e?.health_care_agents?.health_care_agent_two?.cellular_tel
    );

    agent3_nameField.setText(
      e?.health_care_agents?.health_care_agent_three?.full_name
    );
    agent3_addressField.setText(
      e?.health_care_agents?.health_care_agent_three?.house_address
    );
    agent3_home_phoneField.setText(
      e?.health_care_agents?.health_care_agent_three?.home_tel
    );
    agent3_work_phoneField.setText(
      e?.health_care_agents?.health_care_agent_three?.work_tel
    );
    agent3_cellular_phoneField.setText(
      e?.health_care_agents?.health_care_agent_three?.cellular_tel
    );
    physician1Field.setText(e?.physicians[0]);
    physician2Field.setText(e?.physicians[1]);

    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, "power_of_attorney.pdf", "application/pdf");
  };

  React.useEffect(() => {
    fetchAllPOA();
  }, []);

  return (
    <Box>
      <Box>
        {!addPower ? (
          powers.length > 0 ? (
            <>
              <Text color={"#FF6E57"} fontSize={20} fontWeight={600} my={1}>
                Create Power of Attorney
              </Text>
              {powers.map((e, i) => (
                <Box key={i}>
                  <Box h={6} />
                  <Box
                    borderRadius={16}
                    p={6}
                    border={"1px solid #EBECEF"}
                    key={i}
                  >
                    <Flex alignItems={"center"}>
                      <Text fontWeight={600} fontSize={14} mr={1}>
                        Power of Attorney
                      </Text>{" "}
                      <Tag
                        bg={e?.status === "completed" ? "lightgreen" : "orange"}
                        borderRadius={8}
                        color={"black"}
                      >
                        {e?.status}
                      </Tag>
                      <Spacer />
                      <Menu>
                        <MenuButton
                          as={Button}
                          rightIcon={<FaEllipsisV color={"#747A8B"} />}
                        >
                          {" "}
                        </MenuButton>
                        <MenuList border={"none"} boxShadow={"md"}>
                          {e?.status === "draft" ? (
                            <MenuItem onClick={() => handleEditPower(e)}>
                              <Flex alignItems={"center"} color={"#747A8B"}>
                                <FaEdit />
                                <Text ml={1}>Continue</Text>
                              </Flex>
                            </MenuItem>
                          ) : null}

                          <MenuItem onClick={() => editPdf(e)}>
                            <Flex alignItems={"center"} color={"#747A8B"}>
                              <FaPrint />
                              <Text ml={1}>Print Document</Text>
                            </Flex>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setSelectedPOA(e);
                              onOpenUpload();
                            }}
                            key={"uap"}
                          >
                            <Flex alignItems={"center"} color={"#747A8B"}>
                              <BsUpload />
                              <Text ml={1}>Upload Signed Document</Text>
                            </Flex>
                          </MenuItem>
                          <MenuItem onClick={() => editPdf(e)}>
                            <Flex alignItems={"center"} color={"#747A8B"}>
                              <BsDownload />
                              <Text ml={1}>Download Document</Text>
                            </Flex>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setSelectedPOA(e);
                              onOpenDelete();
                            }}
                          >
                            {isDeleting ? (
                              <Spinner />
                            ) : (
                              <Flex alignItems={"center"} color={"#747A8B"}>
                                <BsFillTrash3Fill />
                                <Text ml={1}>Delete Document</Text>
                              </Flex>
                            )}
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box>
                        <Text color={"#747A8B"} mb={3}>
                          Your care plan is to provide guidelines <br /> for
                          care of your jorners.
                        </Text>
                        <Flex alignItems={"center"}>
                          <BiUserCircle /> {e?.basic_information?.jorner_name}
                        </Flex>
                      </Box>
                      <Image src={carePlanImg} h={"63px"} w={"52px"} />
                    </Flex>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Box>
              {loading ? (
                <Stack mt={5}>
                  <Skeleton startColor="#808080" height="50px" />
                  <Skeleton startColor="#808080" height="50px" />
                  <Skeleton startColor="#808080" height="50px" />
                </Stack>
              ) : (
                <>
                  <Text color={"#FF6E57"} fontSize={20} fontWeight={600} my={1}>
                    Power of Attorney
                  </Text>
                  <Text fontSize={14} fontWeight={400} color={"#747A8B"} mb={5}>
                    Invite your co-caregivers and collaborate on the go
                  </Text>
                  <Box mt={12}>
                    <Center>
                      <Image src={powerAttImg} />
                    </Center>
                    <Text textAlign={"center"} fontSize={16} fontWeight={600}>
                      Create power of attorney document in 5 minutes
                    </Text>
                    <Text
                      textAlign={"center"}
                      fontSize={14}
                      fontWeight={400}
                      color={"paragraph"}
                      mt={3}
                    >
                      Sojorne has provided you with a template to make your{" "}
                      <br /> PoA process easier.
                    </Text>
                  </Box>
                </>
              )}
            </Box>
          )
        ) : (
          <Box px={{ base: 2, md: 12 }} py={3}>
            <Flex
              alignItems={"center"}
              gap={2}
              cursor={"pointer"}
              onClick={() => {
                setSelectedPOA(null);
                setAddPower(false);
              }}
              w={28}
            >
              <BsChevronLeft color="#17181C" />
              <Text>Back</Text>
            </Flex>
            <Box h={6} />
            <Text color={"#FF6E57"} fontSize={20} fontWeight={600} my={1}>
              Create Power of Attorney
            </Text>
            <Box h={6} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Accordion defaultIndex={[0]}>
                <AccordionItem
                  key={`basicInfo`}
                  border={"1px solid white"}
                  mb={4}
                >
                  <h2>
                    <AccordionButton bg={"#F7F7F8"}>
                      <Text flex="1" textAlign={"left"}>
                        Basic information{" "}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>

                  <AccordionPanel pb={4} bg={"#FAF8FF"}>
                    <FormControl isInvalid={errors.full_name} mt={4}>
                      <FormLabel
                        htmlFor="full_name"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Full Name
                      </FormLabel>
                      <Input
                        id="full_name"
                        type="text"
                        placeholder="e.g Simon"
                        {...register("full_name", {
                          required: "This is required",
                        })}
                        borderColor={"#E3E4E8"}
                      />
                      <FormErrorMessage>
                        {errors.full_name && errors.full_name.message}
                      </FormErrorMessage>
                    </FormControl>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem key={`agent1`} border={"1px solid white"} mb={4}>
                  <h2>
                    <AccordionButton bg={"#F7F7F8"}>
                      <Text flex="1" textAlign={"left"}>
                        Health Care Agent 1{" "}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg={"#FAF8FF"}>
                    <FormControl isInvalid={errors.agent1_full_name} mt={4}>
                      <FormLabel
                        htmlFor="agent1_full_name"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Full Name{" "}
                      </FormLabel>
                      <Input
                        id="agent1_full_name"
                        placeholder="e.g Simon"
                        {...register("agent1_full_name", {})}
                        borderColor={"#E3E4E8"}
                      />
                      <FormErrorMessage>
                        {errors.agent1_full_name &&
                          errors.agent1_full_name.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.agent1_house_address} mt={4}>
                      <FormLabel
                        htmlFor="agent1_house_address"
                        fontSize={14}
                        fontWeight={600}
                      >
                        House Address{" "}
                      </FormLabel>
                      <Textarea
                        id="agent1_house_address"
                        placeholder="e.g Simon"
                        {...register("agent1_house_address", {})}
                        borderColor={"#E3E4E8"}
                      />
                      <FormErrorMessage>
                        {errors.agent1_house_address &&
                          errors.agent1_house_address.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mt={5}
                      isInvalid={errors.agent1_home_phone}
                      fontSize={14}
                      fontWeight={600}
                    >
                      <FormLabel
                        htmlFor="agent1_home_phone"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Home Telephone
                      </FormLabel>
                      <PhoneNumberInput
                        required={false}
                        key={"agent1_home_phone"}
                        id={"agent1_home_phone"}
                        country={"USA"}
                        value={homePhoneAgent1.home_phone}
                        defaultCountry={countryCodeAgent1.home_phone}
                        options={countryOptions}
                        placeholder="Enter phone number"
                        onChange={(value) =>
                          setHomePhoneAgent1({
                            ...homePhoneAgent1,
                            home_phone: value,
                          })
                        }
                        onChangeCode={(value) =>
                          setCountryCodeAgent1({
                            ...countryCodeAgent1,
                            home_phone: value,
                          })
                        }
                      />
                      <FormErrorMessage>
                        {errors.agent1_home_phone &&
                          errors.agent1_home_phone.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mt={5}
                      isInvalid={errors.agent1_work_phone}
                      fontSize={14}
                      fontWeight={600}
                    >
                      <FormLabel
                        htmlFor="agent1_work_phone"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Work Telephone
                      </FormLabel>
                      <PhoneNumberInput
                        required={false}
                        key={"agent1_work_phone"}
                        id={"agent1_work_phone"}
                        country={"USA"}
                        value={homePhoneAgent1.work_phone}
                        defaultCountry={countryCodeAgent1.work_phone}
                        options={countryOptions}
                        placeholder="Enter phone number"
                        onChange={(value) =>
                          setHomePhoneAgent1({
                            ...homePhoneAgent1,
                            work_phone: value,
                          })
                        }
                        onChangeCode={(value) =>
                          setCountryCodeAgent1({
                            ...countryCodeAgent1,
                            work_phone: value,
                          })
                        }
                      />
                      <FormErrorMessage>
                        {errors.agent1_work_phone &&
                          errors.agent1_work_phone.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mt={5}
                      isInvalid={errors.agent1_cellular_phone}
                      fontSize={14}
                      fontWeight={600}
                    >
                      <FormLabel
                        htmlFor="agent1_cellular_phone"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Cellular Telephone
                      </FormLabel>
                      <PhoneNumberInput
                        required={false}
                        key={"agent1_cellular_phone"}
                        id={"agent1_cellular_phone"}
                        country={"USA"}
                        value={homePhoneAgent1.cellular_phone}
                        defaultCountry={countryCodeAgent1.cellular_phone}
                        options={countryOptions}
                        placeholder="Enter phone number"
                        onChange={(value) =>
                          setHomePhoneAgent1({
                            ...homePhoneAgent1,
                            cellular_phone: value,
                          })
                        }
                        onChangeCode={(value) =>
                          setCountryCodeAgent1({
                            ...countryCodeAgent1,
                            cellular_phone: value,
                          })
                        }
                      />
                      <FormErrorMessage>
                        {errors.agent1_cellular_phone &&
                          errors.agent1_cellular_phone.message}
                      </FormErrorMessage>
                    </FormControl>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem key={`agent2`} border={"1px solid white"} mb={4}>
                  <h2>
                    <AccordionButton bg={"#F7F7F8"}>
                      <Text flex="1" textAlign={"left"}>
                        Health Care Agent 2{" "}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg={"#FAF8FF"}>
                    <FormControl isInvalid={errors.agent2_full_name} mt={4}>
                      <FormLabel
                        htmlFor="agent2_full_name"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Full Name{" "}
                      </FormLabel>
                      <Input
                        id="agent2_full_name"
                        placeholder="e.g Simon"
                        {...register("agent2_full_name", {})}
                        borderColor={"#E3E4E8"}
                      />
                      <FormErrorMessage>
                        {errors.agent2_full_name &&
                          errors.agent2_full_name.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.agent2_house_address} mt={4}>
                      <FormLabel
                        htmlFor="agent2_house_address"
                        fontSize={14}
                        fontWeight={600}
                      >
                        House Address{" "}
                      </FormLabel>
                      <Textarea
                        id="agent2_house_address"
                        placeholder="e.g Simon"
                        {...register("agent2_house_address", {})}
                        borderColor={"#E3E4E8"}
                      />
                      <FormErrorMessage>
                        {errors.agent2_house_address &&
                          errors.agent2_house_address.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mt={5}
                      isInvalid={errors.agent2_home_phone}
                      fontSize={14}
                      fontWeight={600}
                    >
                      <FormLabel
                        htmlFor="agent2_home_phone"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Home Telephone
                      </FormLabel>
                      <PhoneNumberInput
                        required={false}
                        key={"agent2_home_phone"}
                        id={"agent2_home_phone"}
                        country={"USA"}
                        value={homePhoneAgent2.home_phone}
                        defaultCountry={countryCodeAgent2.home_phone}
                        options={countryOptions}
                        placeholder="Enter phone number"
                        onChange={(value) =>
                          setHomePhoneAgent2({
                            ...homePhoneAgent2,
                            home_phone: value,
                          })
                        }
                        onChangeCode={(value) =>
                          setCountryCodeAgent2({
                            ...countryCodeAgent2,
                            home_phone: value,
                          })
                        }
                      />
                      <FormErrorMessage>
                        {errors.agent2_home_phone &&
                          errors.agent2_home_phone.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mt={5}
                      isInvalid={errors.agent2_work_phone}
                      fontSize={14}
                      fontWeight={600}
                    >
                      <FormLabel
                        htmlFor="agent2_work_phone"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Work Telephone
                      </FormLabel>
                      <PhoneNumberInput
                        required={false}
                        key={"agent2_work_phone"}
                        id={"agent2_work_phone"}
                        country={"USA"}
                        value={homePhoneAgent2.work_phone}
                        defaultCountry={countryCodeAgent2.work_phone}
                        options={countryOptions}
                        placeholder="Enter phone number"
                        onChange={(value) =>
                          setHomePhoneAgent2({
                            ...homePhoneAgent2,
                            work_phone: value,
                          })
                        }
                        onChangeCode={(value) =>
                          setCountryCodeAgent2({
                            ...countryCodeAgent2,
                            work_phone: value,
                          })
                        }
                      />
                      <FormErrorMessage>
                        {errors.agent2_work_phone &&
                          errors.agent2_work_phone.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mt={5}
                      isInvalid={errors.agent2_cellular_phone}
                      fontSize={14}
                      fontWeight={600}
                    >
                      <FormLabel
                        htmlFor="agent2_cellular_phone"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Cellular Telephone
                      </FormLabel>
                      <PhoneNumberInput
                        required={false}
                        key={"agent2_cellular_phone"}
                        id={"agent2_cellular_phone"}
                        country={"USA"}
                        value={homePhoneAgent2.cellular_phone}
                        defaultCountry={countryCodeAgent2.cellular_phone}
                        options={countryOptions}
                        placeholder="Enter phone number"
                        onChange={(value) =>
                          setHomePhoneAgent2({
                            ...homePhoneAgent2,
                            cellular_phone: value,
                          })
                        }
                        onChangeCode={(value) =>
                          setCountryCodeAgent2({
                            ...countryCodeAgent2,
                            cellular_phone: value,
                          })
                        }
                      />
                      <FormErrorMessage>
                        {errors.agent2_cellular_phone &&
                          errors.agent2_cellular_phone.message}
                      </FormErrorMessage>
                    </FormControl>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem key={`agent3`} border={"1px solid white"} mb={4}>
                  <h2>
                    <AccordionButton bg={"#F7F7F8"}>
                      <Text flex="1" textAlign={"left"}>
                        Health Care Agent 3{" "}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg={"#FAF8FF"}>
                    <FormControl isInvalid={errors.agent3_full_name} mt={4}>
                      <FormLabel
                        htmlFor="agent3_full_name"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Full Name{" "}
                      </FormLabel>
                      <Input
                        id="agent3_full_name"
                        placeholder="e.g Simon"
                        {...register("agent3_full_name", {})}
                        borderColor={"#E3E4E8"}
                      />
                      <FormErrorMessage>
                        {errors.agent3_full_name &&
                          errors.agent3_full_name.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.agent3_house_address} mt={4}>
                      <FormLabel
                        htmlFor="agent3_house_address"
                        fontSize={14}
                        fontWeight={600}
                      >
                        House Address{" "}
                      </FormLabel>
                      <Textarea
                        id="agent3_house_address"
                        placeholder="e.g Simon"
                        {...register("agent3_house_address", {})}
                        borderColor={"#E3E4E8"}
                      />
                      <FormErrorMessage>
                        {errors.agent3_house_address &&
                          errors.agent3_house_address.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mt={5}
                      isInvalid={errors.agent3_home_phone}
                      fontSize={14}
                      fontWeight={600}
                    >
                      <FormLabel
                        htmlFor="agent3_home_phone"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Home Telephone
                      </FormLabel>
                      <PhoneNumberInput
                        required={false}
                        key={"agent3_home_phone"}
                        id={"agent3_home_phone"}
                        country={"USA"}
                        value={homePhoneAgent3.home_phone}
                        defaultCountry={countryCodeAgent3.home_phone}
                        options={countryOptions}
                        placeholder="Enter phone number"
                        onChange={(value) =>
                          setHomePhoneAgent3({
                            ...homePhoneAgent3,
                            home_phone: value,
                          })
                        }
                        onChangeCode={(value) =>
                          setCountryCodeAgent3({
                            ...countryCodeAgent3,
                            home_phone: value,
                          })
                        }
                      />
                      <FormErrorMessage>
                        {errors.agent3_home_phone &&
                          errors.agent3_home_phone.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mt={5}
                      isInvalid={errors.agent3_work_phone}
                      fontSize={14}
                      fontWeight={600}
                    >
                      <FormLabel
                        htmlFor="agent3_work_phone"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Work Telephone
                      </FormLabel>
                      <PhoneNumberInput
                        required={false}
                        key={"agent3_work_phone"}
                        id={"agent3_work_phone"}
                        country={"USA"}
                        value={homePhoneAgent3.work_phone}
                        defaultCountry={countryCodeAgent3.work_phone}
                        options={countryOptions}
                        placeholder="Enter phone number"
                        onChange={(value) =>
                          setHomePhoneAgent3({
                            ...homePhoneAgent3,
                            work_phone: value,
                          })
                        }
                        onChangeCode={(value) =>
                          setCountryCodeAgent3({
                            ...countryCodeAgent3,
                            work_phone: value,
                          })
                        }
                      />
                      <FormErrorMessage>
                        {errors.agent3_work_phone &&
                          errors.agent3_work_phone.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      mt={5}
                      isInvalid={errors.agent3_cellular_phone}
                      fontSize={14}
                      fontWeight={600}
                    >
                      <FormLabel
                        htmlFor="agent3_cellular_phone"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Cellular Telephone
                      </FormLabel>
                      <PhoneNumberInput
                        required={false}
                        key={"agent3_cellular_phone"}
                        id={"agent3_cellular_phone"}
                        country={"USA"}
                        value={homePhoneAgent3.cellular_phone}
                        defaultCountry={countryCodeAgent3.cellular_phone}
                        options={countryOptions}
                        placeholder="Enter phone number"
                        onChange={(value) =>
                          setHomePhoneAgent3({
                            ...homePhoneAgent3,
                            cellular_phone: value,
                          })
                        }
                        onChangeCode={(value) =>
                          setCountryCodeAgent3({
                            ...countryCodeAgent3,
                            cellular_phone: value,
                          })
                        }
                      />
                      <FormErrorMessage>
                        {errors.agent3_cellular_phone &&
                          errors.agent3_cellular_phone.message}
                      </FormErrorMessage>
                    </FormControl>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem
                  key={`physician`}
                  border={"1px solid white"}
                  mb={4}
                >
                  <h2>
                    <AccordionButton bg={"#F7F7F8"}>
                      <Text flex="1" textAlign={"left"}>
                        Physician{" "}
                        <Text as={"span"} color={"#DB504A"}>
                          *
                        </Text>
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg={"#FAF8FF"}>
                    <FormControl isInvalid={errors.physician1} mt={4}>
                      <FormLabel
                        htmlFor="physician1"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Physician 1{" "}
                      </FormLabel>
                      <Input
                        id="physician1"
                        placeholder="e.g Simon"
                        {...register("physician1", {
                          required: "This is required",
                        })}
                        borderColor={"#E3E4E8"}
                      />
                      <FormErrorMessage>
                        {errors.physician1 && errors.physician1.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.physician2} mt={4}>
                      <FormLabel
                        htmlFor="physician2"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Physician 2{" "}
                      </FormLabel>
                      <Input
                        id="physician2"
                        placeholder="e.g Simon"
                        {...register("physician2", {
                          required: "This is required",
                        })}
                        borderColor={"#E3E4E8"}
                      />
                      <FormErrorMessage>
                        {errors.physician2 && errors.physician2.message}
                      </FormErrorMessage>
                    </FormControl>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem key={`label`} border={"1px solid white"} mb={4}>
                  <h2>
                    <AccordionButton bg={"#F7F7F8"}>
                      <Text flex="1" textAlign={"left"}>
                        Label{" "}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} bg={"#FAF8FF"}>
                    <FormControl isInvalid={errors.label_date} mt={4}>
                      <FormLabel htmlFor="date" fontSize={14} fontWeight={600}>
                        Date{" "}
                      </FormLabel>
                      <Input
                        id="date"
                        type="date"
                        placeholder="Type here..."
                        {...register("label_date", {
                          required: "This is required",
                        })}
                        borderColor={"#E3E4E8"}
                      />

                      <FormErrorMessage>
                        {errors.label_date && errors.label_date.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.label_full_name} mt={4}>
                      <FormLabel
                        htmlFor="label_full_name"
                        fontSize={14}
                        fontWeight={600}
                      >
                        Full Name{" "}
                      </FormLabel>
                      <Input
                        id="label_full_name"
                        type="text"
                        placeholder="Type here..."
                        {...register("label_full_name", {
                          required: "This is required",
                        })}
                        borderColor={"#E3E4E8"}
                      />

                      <FormErrorMessage>
                        {errors.label_full_name &&
                          errors.label_full_name.message}
                      </FormErrorMessage>
                    </FormControl>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Button
                mt={8}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                background={"primary"}
                isLoading={isSubmitting}
                type="submit"
              >
                Save & Download
              </Button>
              <Button
                mt={1}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#747A8B"
                borderRadius={64}
                background={"#fff"}
                border={"1px solid #EBECEF"}
                isLoading={isSubmitting}
                type="submit"
              >
                Save as Draft
              </Button>
            </form>

            <Modal
              isOpen={isOpen}
              onClose={onClose}
              size={"xl"}
              isCentered
              closeOnOverlayClick={false}
            >
              <ModalOverlay />
              <ModalContent borderRadius={16}>
                <ModalHeader></ModalHeader>
                <ModalCloseButton>
                  <Box
                    p={2}
                    mr={5}
                    bg={"#F6F6F6"}
                    borderRadius={100}
                    color={"#17181C"}
                    fontWeight={500}
                    fontSize={14}
                  >
                    Close
                  </Box>
                </ModalCloseButton>
                <ModalBody>
                  <Box>
                    <Text textAlign={"center"} fontSize={16} fontWeight={600}>
                      Save & Download
                    </Text>
                    <Text
                      textAlign={"center"}
                      fontSize={14}
                      fontWeight={400}
                      color={"#747A8B"}
                    >
                      Download and share your power of attorney
                    </Text>
                    <Center mt={2}>
                      <Image src={carePlanImg} />
                    </Center>
                    <Box h={4} />
                    <Divider variant={"dashed"} color={"#747A8B"} />
                    <Box h={4} />
                    <Text fontSize={14} fontWeight={600}>
                      Next steps after downloading{" "}
                    </Text>
                    <UnorderedList color={"#747A8B"} mt={3}>
                      <ListItem>Print the downloaded pdf file</ListItem>
                      <ListItem>Take file to your witness</ListItem>
                      <ListItem>Upload witness signed document</ListItem>
                    </UnorderedList>
                  </Box>
                  <Button
                    mt={8}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#fff"
                    borderRadius={64}
                    background={"primary"}
                    //   isLoading={isLoadingJoinCommunity}
                    onClick={handleDownloadPOA}
                  >
                    Download Document
                  </Button>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        )}
      </Box>
      {!addPower && userPermission.includes("documents_power_of_attorney:create") ? (
        <Box>
          <Center>
            <Button
              mt={10}
              mb={4}
              size={"lg"}
              w={{
                base: "100%",
                md: "50%",
              }}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={() => {
                if (subscription.power_of_attorney != "unlimited") {
                  const sAttorney = parseInt(subscription.power_of_attorney);
                  if (powers.length >= sAttorney) {
                    return toast({
                      title: "Access Denied!",
                      description: `Please upgrade your account to create more Power of Attorney`,
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                }
                setSelectedPOA(null);
                setAddPower(true);
              }}
            >
              Create document
            </Button>
          </Center>
          <Text textAlign={"center"} fontSize={12} fontWeight={400}>
            {" "}
            Disclaimer: Note that Sojorne only provides a power of attorney
            draft.
          </Text>
        </Box>
      ) : null}

      <Modal
        key={"uploadAccess"}
        isOpen={isOpenUpload}
        onClose={onCloseUpload}
        size={"xl"}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius={16}>
          <ModalHeader>Upload Assessed Plan</ModalHeader>
          <ModalCloseButton>
            <Box
              p={2}
              mr={5}
              bg={"#F6F6F6"}
              borderRadius={100}
              color={"#17181C"}
              fontWeight={500}
              fontSize={14}
            >
              Close
            </Box>
          </ModalCloseButton>
          <ModalBody>
            <form onSubmit={handleSubmitUpload(onSubmitUpload)}>
              <Box
                border={"1px dashed #E3E4E8"}
                h={32}
                mt={4}
                onClick={chooseFilePlan}
                cursor={"pointer"}
              >
                <Center mt={8}>
                  <Text fontSize={14} color={"secondary"}>
                    Browse file
                  </Text>
                </Center>
                <Text textAlign={"center"} fontSize={12}>
                  Formats: pdf
                </Text>
              </Box>
              <Input
                ref={accessedPlanRef}
                type={"file"}
                onChange={(e) => imageHandlerAP(e)}
                hidden
              />
              {accessedPlanImg ? (
                <Box
                  display={"flex"}
                  border={"1px solid #E3E4E8"}
                  p={4}
                  mt={2}
                  alignItems={"center"}
                  gap={3}
                >
                  <FaFile />
                  <Text>
                    {accessedPlanImg?.name} <br />{" "}
                    <Text fontSize={12} fontWeight={400} color={"#747A8B"}>
                      {accessedPlanImg?.size / 1000} kb
                    </Text>
                  </Text>
                </Box>
              ) : null}

              <Button
                mt={8}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                background={"primary"}
                isLoading={isSubmittingUpload}
                isDisabled={accessedPlanImg == null}
                type="submit"
              >
                Upload
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* delete modal */}
      <CustomModal
        onClose={onCloseDelete}
        onDone={onCloseDelete}
        isOpen={isOpenDelete}
        body={
          <Box>
            <Center>
              <Image src={deleteIcon} />
            </Center>
            <Text textAlign={"center"} fontSize={20} fontWeight={600}>
              Delete?
            </Text>
            <Text textAlign={"center"} fontSize={16} fontWeight={400}>
              The selected power of attorney will be deleted
            </Text>
            <Button
              mt={10}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={onCloseDelete}
            >
              Cancel
            </Button>
            <Button
              mt={1}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="secondary"
              borderRadius={64}
              border={"1px solid #EBECEF"}
              background={"white"}
              onClick={() => handleDeletePower(selectedPOA?.id)}
            >
              {isDeleting ? (
                <Spinner size={"sm"} color="#747A8B" />
              ) : (
                "Yes delete"
              )}
            </Button>
          </Box>
        }
      />
    </Box>
  );
}
