import { SearchIcon, SpinnerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
  FormLabel,
  Icon,
  Avatar,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { PlusIcon } from "@heroicons/react/24/solid";
import ResendNotificationModal from "../onboarding/resend_notfication_modal";

export default function WhoModal({
  isOpen,
  onClose,
  title = null,
  constants,
  selectedOriginal,
  onFinished,
  source = "main",
  buttonTitle = "",
  activeList = [],
  invite=false
}) {
  const toast = useToast()
  const [selected, setselected] = React.useState(selectedOriginal);
  const [active, setActive] = React.useState([]);
  const [itemid, setItemId] = React.useState("");
  const [showInvite, setShowInvite] = React.useState(false);
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const isCoCaregiver = user.tag !== "cg";
  const finishSelection = () => {
    if(source !== "main") {
      if(!selected) {
        return toast({
          title: "Please select one or more co-caregivers",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      if(selected.length == 0) {
        return toast({
          title: "Please select one or more co-caregivers",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }

    onFinished(selected);
    onClose();
    setselected([]);
  };
  useEffect(() => {
    setselected(selectedOriginal);
    setActive(activeList);
    setShowInvite(invite)
  }, [selectedOriginal, activeList]);
  const {
    isOpen: isOpenResend,
    onOpen: onOpenResend,
    onClose: onCloseResend,
  } = useDisclosure();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (source !== "main") {
          onFinished("close");
        } else {
          onClose();
        }
      }}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalHeader>{title || "Who is in charge"}</ModalHeader>
        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>
        <ModalBody marginBottom={"0"}>
          {constants.map((item, i) => (
            <>
              {active.find((l) => l.id == item.id) ? (
                <></>
              ) : (
                <Box cursor={"pointer"} key={i} py="2">
                  <Flex justifyContent={"space-between"}>
                    <FormLabel
                      gap={"3"}
                      display="flex"
                      alignItems={"center"}
                      htmlFor={`${item.user_id}`}
                    >
                      {
                        item.avatar 
                        ?
                        <Image
                        boxSize={"45px"}
                        borderRadius={"100%"}
                        src={item.avatar}
                        />
                        :
                        <Box
                        position={"relative"}
                        fontWeight="600"
                        color="white"
                        boxSize={"45px"}
                        borderRadius={"100%"}
                        display="flex"
                        background={"secondary"}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text fontSize={"14px"}>
                          {item.first_name.substring(0, 1)}
                          {item.last_name.substring(0, 1)}
                        </Text>
                      </Box>
                      }
                      
                      <Box>
                        <Box display={"flex"}>
                          <Box flex={1}>
                            <Text>
                              {item.first_name} {item.last_name}
                            </Text>

                          </Box>
                          
                        </Box>
                        {source == "community" && (
                          <>
                            <Text color={"#747A8B"} fontSize={12}>
                              {item.email}
                            </Text>
                            <Box display={"flex"} gap={"2"} mt={"1"} flexDir={{
                              base : "column", md : "row"
                            }}> 
                            <Box
                              w={"fit-content"}
                              p={2}
                              py={1}
                              borderRadius={12}
                              bg={"primary"}
                              fontSize={10}
                              color={"#fff"}

                            >
                              {item.caregiver_type}
                            </Box>
                            <Box
                              w={"fit-content"}
                              p={2}
                              py={1}
                              borderRadius={12}
                              bg={`${item.ccg_status == 'pending' ? "secondary" : "primary"}`}
                              fontSize={10}
                              color={"#fff"}
                            >
                              {item.ccg_status}
                            </Box>
                            </Box>
                          </>
                        )}
                      </Box>
                    </FormLabel>
                    <Box display={"flex"} flexDirection={"column"} >

                      {
                        selected.includes(title ? item.id : item.user_id)
                        ?
                        <Box 
                        onClick={()=> {
                          var s = selected;
                          var newS = [];
                            var i = s.indexOf(title ? item.id : item.user_id);
                            for (let index = 0; index < s.length; index++) {
                              const element = s[index];
                              let lookup = title ? element == item.id : element == item.user_id;
                              if(!lookup){
                                newS = [...newS, element];
                              }
                            }
                            setTimeout(() => {
                              setselected(newS);
                            }, 400);
                        }}>
                        <Checkbox
                        readOnly
                        isChecked={true}
                        id={`${title ? item.id : item.user_id}`}
                        size="sm"
                        colorScheme="red"
                      ></Checkbox>
                      </Box>
                        :
                        <Box
                        onClick={()=> {
                          var s = selected;
                          if (s.includes(title ? item.id : item.user_id)) {
                          } else {
                            s = [...s, title ? item.id : item.user_id];
                          }
                          setTimeout(() => {
                            setselected(s ? s : []);
                          }, 400);

                        }}>
                        <Checkbox
                        readOnly
                       
                        isChecked={false}
                        id={`${title ? item.id : item.user_id}`}
                        size="sm"
                        colorScheme="red"
                      ></Checkbox>
                      </Box>
                      }
                   
                    {
                          source == "community"  &&
                        <>
                        <Box h={2} />
                        {item.ccg_status == "pending" && (
                          <Box
                            cursor={"pointer"}
                            onClick={()=> {
                              onOpenResend()
                              setItemId(item.id)
                            }
                            }
                            >
                            <SpinnerIcon color="#747A8B" />
                            </Box>
                        )}
                        </>
                        }
                    </Box>
                   
                  </Flex>
                  <Divider mt={"1"} color={"#EBECEF"} />
                </Box>
              )}
            </>
          ))}
          {
            constants.length ==0
            ?
            <Box textAlign={"center"}>
               <Box mb={8}>
            <Text fontSize={20} fontWeight={600}>
              Build Your Care Team
            </Text>
            <Text fontSize={14} fontWeight={400} color={"#747A8B"}>
              Invite your co-caregivers and collaborate on the go
            </Text>
          </Box>
            </Box>
            : 
          <Button
            onClick={finishSelection}
            mt={8}
            mb="3"
            size={"lg"}
            w={"100%"}
            shadow={"sm"}
            colorScheme="purple"
            color="#fff"
            borderRadius={64}
          >
            {buttonTitle && title
              ? buttonTitle
                ? buttonTitle
                : "Share Note"
              : "Save"}
          </Button>
          }
          {(source == "community" || source == "note") && (
            <>
              {isCoCaregiver ? (
                <></>
              ) : (
                <Box
                  w="100%"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  color={"primary"}
                  mt={"15px"}
                  cursor={"pointer"}
                  onClick={() => {
                    onFinished("invite");
                  }}
                >
                  <Icon as={PlusIcon} fontSize={24} />
                  <Text fontWeight={"bold"}>Invite Co-caregiver</Text>
                </Box>
              )}
            </>
          )}
          <Box height={"25px"} />
          <ResendNotificationModal
        onClose={onCloseResend}
        isOpen={isOpenResend}
        caregiver={itemid}
      />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
