import {
  HStack,
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
  Stack,
  Skeleton,
  SimpleGrid,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  useToast,
  InputGroup,
  Input,
  InputLeftAddon,
} from "@chakra-ui/react";
import "../../utils/mp3.css";

import { Link as ReachLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";
import {
  finance,
  heroiconsTag,
  iCOutlineNote,
  majestiIconsNote,
  musicIcon,
  noNotes,
  noise,
  notask,
  noteIcon,
  provider,
  team,
  tribe,
  waves,
  who,
} from "../../images";
import AppLink from "../../components/link";
import NoTask from "../../components/dashboard/no_task";
import {
  CalendarDaysIcon,
  ChevronRightIcon,
  PlusIcon,
  TagIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import { isValidUrl } from "../../utils/utils";
import { FaNotesMedical, FaShare } from "react-icons/fa";
import moment from "moment";
import {
  BiAlarm,
  BiDotsHorizontal,
  BiDotsVertical,
  BiTag,
} from "react-icons/bi";
import { DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import CreateTagModal from "./create_tag_modal";
import DeleteNote from "./delete_note_modal";
import { ShareIcon } from "@heroicons/react/24/outline";
import WhoModal from "../tasks/who_modal";
import { SearchBox } from "../../components/search_box";
import InviteCareGiverModal from "../dashboard/co_caregiver_modal";

function NotesPage() {
  const toast = useToast();
  const [coCaregivers, setCoCaregivers] = useState([]);

  const [selectedNote, setSelectedNote] = useState("");
  const [selectedNoteName, setSelectedNoteName] = useState("");
  const [inviteGiversConstants, setInviteGiversConstants] = useState([]);
  const [jorners, setJorners] = React.useState([]);

  const [tags, setTags] = useState([]);
  const [notes, setNotes] = useState([]);
  const [notesOriginal, setNotesOriginal] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const isCoCaregiver = user.tag !== "cg";
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenWhoModal,
    onOpen: onOpenWhoModal,
    onClose: onCloseWhoModal,
  } = useDisclosure();
  const {
    isOpen: isOpenCoGiverModal,
    onOpen: onOpenCoGiverModal,
    onClose: onCloseCoGiverModal,
  } = useDisclosure();
  useEffect(() => {
    loadConstants();
    getCocaregivers();
    getJorners();
    fetchCaregiverconstants();
  }, [true]);

  const loadConstants = async () => {
    const res = await httpGetWithTokenRest("notes");
    if (res.statusCode == 200) {
      setNotes(res.data.reverse());
      setNotesOriginal(res.data.reverse());
    }
    setLoading(false);
  };
  const fetchCaregiverconstants = async () => {
    var j = await httpGetWithTokenRest("permissions");
    if (j.status == "success") {
      setInviteGiversConstants(j.data.permissions);
    }
  };
  const getJorners = async () => {
    var j = await httpGetWithTokenRest("co_caregiver");
    var url;
    if (user.tag == "cocg") {
      url = `co_caregiver/${user.id}/jorners`;
    } else {
      url = `jorner`;
    }
    var j = await httpGetWithTokenRest(url);
    if (j.status == "success") {
      const jor = j.data;
      const jornersss = jor.map((item, i) => {
        return {
          id: i,
          key: i,
          label: item.first_name + " " + item.last_name,
          value: item.id,
          ...item,
        };
      });
      setJorners(jornersss);
    }
    // setJorners(j.data);
  };
  const dangerousHTML = (content) => {
    var c = content.substring(0, 150);

    return content;
  };
  const finishSelectionType = async (arr, type = "pending") => {
    setTags(arr);
    let newNotes = [];
    if (type == "save") {
      for (let n = 0; n < notes.length; n++) {
        const element = notes[n];
        if (element.id === selectedNote) {
          element.tags = arr;
        }
        newNotes = [...newNotes, element];
      }
      var response = await httpPutWithTokenRest("notes/" + selectedNote, {
        tags: arr,
      });
      if (response.error) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `${response.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setNotes(newNotes);
        setNotesOriginal(newNotes);
        return toast({
          title: "Jorner schedule tags updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };
  const getCocaregivers = async () => {
    var j = await httpGetWithTokenRest("co_caregiver");
    if (j.status == "success") {
      let d = j.data;
      setCoCaregivers(d);
    }
  };
  const finishedSelection = async (e) => {
    if (e == "close") {
      onCloseWhoModal();
    } else if (e == "invite") {
      onCloseWhoModal();
      onOpenCoGiverModal();

    } else {
      var j = await httpPostWithToken("notes/share/" + selectedNote, {
        cocg_ids: e,
      });

      if (j.statusCode == 200) {
        return toast({
          title: "Jorner Schedule shared successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.log(j);
        return toast({
          title: j.error || "An error occured",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };
  const handleSearch = (keyword) => {
    if (keyword.length > 0) {
      const filter = notesOriginal.filter((e) =>
        e?.title?.toLowerCase().includes(keyword.toLowerCase())
      );

      setNotes(filter);
    } else {
      setNotes(notesOriginal);
    }
  };
  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
      <CreateTagModal
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
        selectedOriginal={tags}
        onFinished={finishSelectionType}
        type="edit"
      />
      <WhoModal
        isOpen={isOpenWhoModal}
        title={"Share - " + selectedNoteName}
        onFinished={finishedSelection}
        constants={coCaregivers}
        selectedOriginal={[]}
        onClose={onCloseWhoModal}
        source="note"
        activeList={[]}
      />
      <DeleteNote
        onClose={() => {
          onCloseDelete();
        }}
        type="all"
        isOpen={isOpenDelete}
        note={selectedNote}
        onFinished={() => {
          loadConstants();
        }}
      />
      <InviteCareGiverModal
            coGiverConstants={inviteGiversConstants}
            onClose={onCloseCoGiverModal}
            isOpen={isOpenCoGiverModal}
            jorners={jorners}
            community={""}
            callback={()=> {
              getCocaregivers();
            }}
          />
      <Spacer height={"22px"} />
      <Box>
        <Flex alignItems={"center"} justifyContent="space-between">
          <Heading as={"h3"} fontSize={"18px"} fontWeight="600" color="black">
            Jorner’s Schedule
          </Heading>
          {!loading && notes.length > 0 && (
            <Button
              borderRadius={"50px"}
              onClick={() => navigate("/jorner-schedule/create")}
              colorScheme={"purple"}
            >
              {" "}
              <Icon color={"white"} as={PlusIcon} />
              <Text ml={1} display={{ base: "none", md: "block" }}>
                Create Schedule
              </Text>
            </Button>
          )}
        </Flex>
        <Box h="10px" />
        <Box display={"flex"} justifyContent={"end"}>
          <SearchBox placeholder="Search" handleSearch={handleSearch} />
        </Box>
        <Box h="10px" />
        {loading && (
          <Stack mt={5}>
            <Skeleton startColor="#808080" height="50px" />
            <Skeleton startColor="#808080" height="50px" />
            <Skeleton startColor="#808080" height="50px" />
          </Stack>
        )}
      </Box>
      <Spacer height={"22px"} />
      {!loading && notes.length === 0 ? (
        <>
          <Box
            shadow={"sm"}
            background="white"
            padding="100px"
            borderRadius={"16px"}
            display="flex"
            flexDirection="column"
            justifyContent={"center"}
            alignItems="center"
          >
            <Image src={noNotes} boxSize={"155px"}></Image>
            <Box h="10px" />
            <Box h="10px" />
            <Heading as="h6" fontSize={"16px"}>
              Jorner Schedule
            </Heading>
            <Box h="10px" />

            <Box textAlign={"center"} maxW="480px">
              <Text color="paragraph">
                Create Jorner’s schedule and manage jorners ...
              </Text>
              <Box h="10px" />

              <Box h="5px"></Box>
              <AppLink
                href="/jorner-schedule/create"
                hasLeftIcon={true}
                leftIcon={<Icon as={PlusIcon} fontSize={"22px"} />}
                textDecoration="none"
                text="Add New Note"
                color="red"
                padding="0"
                height="fit-content"
                background="transparent"
                fontWeight="500"
                shadow="none"
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5} mt={5}>
            {notes.map((note, i) => (
              <Box
                key={i}
                cursor={"pointer"}
                // height={"106px"}
                padding={"12px 10px"}
                overflow={"hidden"}
                shadow={"sm"}
                borderRadius={"10px"}
                bg={
                  [
                    "#FFBA2D",
                    "#589100",
                    "#7353BA",
                    "#FA945C",
                    "#E474F2",
                    "#65CBE9",
                  ][Math.floor(Math.random() * 6)]
                }
              >
                <Flex w="100%" justifyContent={"space-between"}>
                  <Flex alignItems={"center"} gap={"3"}>
                    {!isValidUrl(note.content) ? (
                      <Image boxSize={"20px"} src={majestiIconsNote} />
                    ) : (
                      <Image boxSize={"20px"} src={musicIcon} />
                    )}
                    <Heading
                      color={"white"}
                      fontSize={"16px"}
                      fontWeight={"600"}
                    >
                      {note.title.substring(0, 20)}{" "}
                      {note.title.length > 20 && "..."}{" "}
                    </Heading>
                  </Flex>
                  <Menu>
                    <MenuButton
                      background="transparent"
                      border={"0"}
                      width={"4px"}
                      color="white"
                      fontSize={"13px"}
                      as={Button}
                      rightIcon={<BiDotsVertical />}
                      fontWeight={600}
                    ></MenuButton>
                    <MenuList width={"100px"} border={0} shadow={"sm"}>
                      <MenuItem
                        width={"100px"}
                        onClick={() => {
                          navigate("/jorner-schedule/" + note.id + "/edit");
                        }}
                      >
                        <Image
                          boxSize={"20px"}
                          objectFit={"contain"}
                          src={iCOutlineNote}
                        />
                        &nbsp; Open
                      </MenuItem>
                      <MenuItem
                        width={"100px"}
                        onClick={() => {
                          setTags(note.tags);
                          setSelectedNote(note.id);
                          setTimeout(() => {
                            onOpenCreate();
                          }, 500);
                        }}
                      >
                        <Image
                          boxSize={"20px"}
                          objectFit={"contain"}
                          src={heroiconsTag}
                        />
                        &nbsp; Tags
                      </MenuItem>
                      <MenuItem
                        gap={"3"}
                        display={"flex"}
                        width={"100px"}
                        onClick={() => {
                          setTags(note.tags);
                          setSelectedNote(note.id);
                          setSelectedNoteName(note.title);
                          setTimeout(() => {
                            onOpenWhoModal();
                          }, 500);
                        }}
                      >
                        <ShareIcon />
                        Share
                      </MenuItem>
                      <MenuItem
                        gap={"3"}
                        display={"flex"}
                        width={"100px"}
                        onClick={() => {
                          setTags(note.tags);
                          setSelectedNote(note.id);
                          setTimeout(() => {
                            onOpenDelete();
                          }, 500);
                        }}
                      >
                        <DeleteIcon />
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>

                <Box height={"10px"} />
                <Box height={"10px"} />

                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Flex gap="2" alignItems={"center"}>
                    <Icon as={CalendarDaysIcon} color={"white"} />
                    <Text color={"white"} fontSize={"13px"}>
                      {moment(note.created_at).format("YYYY-MMM-D")}
                    </Text>
                  </Flex>

                  <Flex gap="2" alignItems={"center"}>
                    <Icon as={BiAlarm} color={"white"} />
                    <Text color={"white"} fontSize={"13px"}>
                      {moment(note.created_at).format("h:mm:ss a")}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            ))}
          </SimpleGrid>
        </>
      )}
    </Box>
  );
}

export default NotesPage;
