import { ChevronLeftIcon, Icon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Heading,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { circleSomething, infoARROWICON } from "../../images";
import { PlusIcon } from "@heroicons/react/24/solid";
import { communityRoute } from "../../utils/allRoutes";

export default function CarecircleInfoModal({
  isOpen,
  onClose,
  title = null,
  constants,
  onFinished,
  source = "main",
  jorner = null,
}) {
  const [list, setList] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const isCoCaregiver = user.tag !== "cg";
  const [child, setJorner] = useState({});
  const finishSelection = (item = null) => {
    onFinished(item);
    onClose();
  };
  useEffect(() => {
    let c = constants.slice(0, 4);
    setList(c);
    setJorner(jorner);
  }, [constants, jorner]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (source !== "main") {
          onFinished("close");
        } else {
          onClose();
        }
      }}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        maxW={"500px"}
        minH={"500px"}
        bg={"primary"}
        borderRadius={16}
      >
        <ModalBody marginBottom={"0"}>
          <Box h={"5px"} />
          <Box display={"flex"} gap={3} alignItems={"center"} py={4}>
            <Box
              cursor={"pointer"}
              onClick={() => {
                finishSelection("close");
              }}
            >
              <ChevronLeftIcon color={"white"} fontSize={"24px"} />
            </Box>
            <Heading color={"white"} flex={1} fontSize={"16px"}>
              {title}'s Care Circle
            </Heading>
          </Box>
          <Box h={"15px"} />

          <Box
            bg={"#FFFFFF57"}
            p={3}
            borderRadius={8}
            display={"flex"}
            color={"white"}
            alignItems={"center"}
          >
            <Box flex={1}>
              <Text fontSize={"15px"}>🚀 Quick Guide</Text>
              <Text fontSize={"12px"}>
                We curated a step by step guide to help you get the most out of
                our care circle feature.
              </Text>
            </Box>
            <Image src={infoARROWICON} boxSize={17} />
          </Box>
          <Box my="3" display={"flex"} justifyContent={"center"}>
            <Box mx={"auto"} position={"relative"}>
              <Box
                bg="white"
                p={"2px"}
                position={"absolute"}
                top={150 - 34}
                left={150 - 34}
                borderRadius={"100%"}
              >
                {child?.avatar ? (
                  <Image
                    boxSize={"62px"}
                    borderRadius={"100%"}
                    src={child?.avatar}
                  />
                ) : (
                  <Box
                    fontWeight="600"
                    color="white"
                    boxSize={"62px"}
                    borderRadius={"100%"}
                    display="flex"
                    background={"secondary"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize={"14px"}>
                      {child?.first_name?.substring(0, 1)}
                      {child?.last_name?.substring(0, 1)}
                    </Text>
                  </Box>
                )}
              </Box>
              {list.map((item, i) => {
                return (
                  <Box
                    bg="white"
                    p={"2px"}
                    borderRadius={"100%"}
                    top={i == 0 ? 0 : i == 2 ? 300 - 60 : 150 - 34}
                    left={i == 2 || i == 0 ? 150 - 34 : i == 1 ? 0 : 300 - 60}
                    position={"absolute"}
                    key={item.id}
                  >
                    {item.avatar ? (
                      <Image
                        boxSize={"62px"}
                        borderRadius={"100%"}
                        src={item.avatar}
                      />
                    ) : (
                      <Box
                        fontWeight="600"
                        color="white"
                        boxSize={"62px"}
                        borderRadius={"100%"}
                        display="flex"
                        background={"secondary"}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text fontSize={"14px"}>
                          {item.first_name.substring(0, 1)}
                          {item.last_name.substring(0, 1)}
                        </Text>
                      </Box>
                    )}
                  </Box>
                );
              })}
              <Image
                objectFit={"fill"}
                src={circleSomething}
                boxSize={"300px"}
              />
            </Box>
          </Box>
          <Box w="100%" mt={"25px"}>
            <Button
              size={"sm"}
              w={"100%"}
              border={"1px solid white"}
              color="primary"
              borderRadius={64}
              background={"white"}
              height={"45px"}
              onClick={() => onFinished("view")}
            >
              View all Caregivers
            </Button>
          </Box>

          <Box w="100%" mt={"25px"}>
            <Button
              size={"sm"}
              w={"100%"}
              border={"1px solid white"}
              height={"45px"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={() => {
                onFinished("chat");
              }}
            >
              Start Conversation with Care Circle
            </Button>
          </Box>
          {isCoCaregiver ? (
            <></>
          ) : (
            <Box
              w="100%"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              color={"white"}
              mt={"25px"}
              cursor={"pointer"}
              onClick={() => {
                onFinished("invite");
              }}
            >
              <Icon as={PlusIcon} fontSize={24} />
              <Text>Invite Co-caregiver</Text>
            </Box>
          )}

          <br />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
