import {
  HStack,
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
  Stack,
  Skeleton,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import AudioPlayer from "react-h5-audio-player";
import "../../utils/mp3.css";

import { Link as ReachLink } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";
import {
  finance,
  insuranceNotFound,
  noInsurance,
  noNotes,
  noise,
  notask,
  provider,
  team,
  tribe,
  waves,
  who,
} from "../../images";
import AppLink from "../../components/link";
import NoTask from "../../components/dashboard/no_task";
import { ChevronRightIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { httpGetWithTokenRest } from "../../utils/http_utils";
import { isValidUrl } from "../../utils/utils";
import CreatInsuranceModal from "./create_insurance_modal";
import CreatInsuranceModal2 from "./create_insurance_modal_2";
import AppointmentCreatedModal from "./insurance_created";
import InsuranceCard from "./insurance_card";
import { AppContext } from "../../redux/appContext";


function InsurancePage() {
  const { subscription} = useContext(AppContext)

  const [tasktype, setTasktype] = useState("");
  const [delAppointment, setDelAppointment] = useState(null);
  const [insurance, setInsurance] = useState([]);
  const [jorners, setJorners] = useState([]);
  const [newInsurance, setNewInsurance] = useState({});
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const toast = useToast()
  const colors = ["#EBECEF", "#FFDCC9", "#FFF6E3", "#E5EDFF", "#FFDCC9"];
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const {
    isOpen: isOpenCreate2,
    onOpen: onOpenCreate2,
    onClose: onCloseCreate2,
  } = useDisclosure();

  const {
    isOpen: isOpenCreated,
    onOpen: onOpenCreated,
    onClose: onCloseCreated,
  } = useDisclosure();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  useEffect(() => {
      if(subscription.insurance_cards == "0" || subscription.insurance_cards == 0){
        navigate("/access-denied")
      }
    loadConstants();
  }, []);

  const loadConstants = async (from = null) => {
    if (!from) setDelAppointment(null);
    const res = await httpGetWithTokenRest("insurance");
    if (res.statusCode == 200) {
      setInsurance(res.data.reverse());
    }
    setLoading(false);
  };

  const dangerousHTML = (content) => {
    var c = content.substring(0, 150);

    return content;
  };



  const editAppointment = (appointment) => {
    // console.log("edited", appointment)
    setDelAppointment(appointment);
    onOpenCreate();
  };

  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
      <Spacer height={"22px"} />
      <CreatInsuranceModal
        onClose={onCloseCreate}
        isOpen={isOpenCreate}
        onFinished={(callbackValue) => {
          onCloseCreate()
          if (callbackValue != 'close') {
            setNewInsurance({ insurance_type: callbackValue })
            onOpenCreate2()
          }
        }}
      />

      <CreatInsuranceModal2
        onClose={onCloseCreate2}
        newinsurance={newInsurance}
        isOpen={isOpenCreate2}
        onFinished={() => {
          onOpenCreated()
          loadConstants()
        }}
      />

      <AppointmentCreatedModal
        onClose={onCloseCreated}
        isOpen={isOpenCreated}
      />

      <Box>
        <Flex gap={'4'} alignItems={{base : 'start', md : "center"}} flexDirection={{base : 'column', md : 'row'}} justifyContent="space-between">
          <Box>

            <Heading as={"h3"} fontSize={"18px"} fontWeight="600" color="black">
              Insurance
            </Heading>
            <Text color={"paragraph"}>Add your insurance card to the application</Text>
          </Box>
          {!loading && (
            <Button
            size={'sm'}
              borderRadius={"50px"}
              onClick={()=> {
                if(subscription.insurance_cards != "unlimited") {
                  const sInsurance = parseInt(subscription.insurance_cards) 
                  if(insurance.length >= sInsurance){
                    return toast({
                      title: "Access Denied!",
                      description: `Please upgrade your account to create more Insurance Cards`,
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                }
                onOpenCreate()
              }}
              colorScheme={"purple"}
            >
              {" "}
              <Icon color={"white"} as={PlusIcon} />
              &nbsp; Add Insurance Card
            </Button>
          )}
        </Flex>
        <Box h="10px" />
        {loading && (
          <Stack mt={5}>
            <Skeleton startColor="#808080" height="50px" />
            <Skeleton startColor="#808080" height="50px" />
            <Skeleton startColor="#808080" height="50px" />
          </Stack>
        )}
      </Box>
      <Spacer height={"22px"} />
      {!loading && insurance.length === 0 ? (
        <>
          <Box
            shadow={"sm"}
            background="white"
            padding={{base : '3', md :"100px"}}
            borderRadius={"16px"}
            display="flex"
            flexDirection="column"
            justifyContent={"center"}
            alignItems="center"
          >
            <Image src={insuranceNotFound} boxSize={"155px"}></Image>
            <Box h="10px" />
            <Box h="10px" />
            <Heading as="h6" fontSize={"16px"}>
              No Insurance Card Added Yet!
            </Heading>
            <Box h="10px" />

            <Box textAlign={"center"} maxW="480px">
              <Text color="paragraph">
                Add your insurance card to the application
              </Text>
              <Box h="10px" />

              <Box h="5px"></Box>
              <AppLink
                href="#?"
                onclick={()=>{
                  if(subscription.insurance_cards != "unlimited") {
                    const sInsurance = parseInt(subscription.insurance_cards) 
                    if(insurance.length >= sInsurance){
                      return toast({
                        title: "Access Denied!",
                        description: `Please upgrade your account to create more Insurance Cards`,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                  }
                  onOpenCreate()
                }
                }
                hasLeftIcon={true}
                leftIcon={<Icon as={PlusIcon} fontSize={"22px"} />}
                textDecoration="none"
                text="Create Add Insurance Card "
                color="red"
                padding="0"
                height="fit-content"
                background="transparent"
                fontWeight="500"
                shadow="none"
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            shadow={"sm"}
            background="white"
            padding="3"
            borderRadius={"16px"}
          >
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
              {insurance.map((card, i) => (
                <InsuranceCard
                  editAppointment={editAppointment}
                  card={card}
                  key={i}
                />
              ))}
            </SimpleGrid>
          </Box>
        </>
      )}
    </Box>
  );
}

export default InsurancePage;
